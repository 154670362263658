<template>
  <div class="top-header-strip__background">
    <div class="top-header-strip__gap-filler"></div>
    <div class="top-header-strip">
      <div class="top-header-strip__content-wrapper">
        <!-- FM Group Products text will be returned as soon as requested by Ramzi -->
        <span class="top-header-strip__text fm-group"></span>
        <div class="top-header-strip__forexlive-wrapper">
          <a-link
            :to="this.$env.FL_DOMAIN_URL"
            open-in-new-tab
            class="top-header-strip__text forexlive"
          >
            Market news, analysis & forecast powered by
          </a-link>
          <a-icon
            v-bind="$options.consts.flLogoIconProps"
            class="forexlive-icon"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AIcon, { ICON } from 'shared/AIcon'

export default {
  name: 'TopHeaderStrip',
  components: { AIcon },
  consts: {
    flLogoIconProps: {
      icon: ICON.FL,
      width: 78,
      height: 25,
      fill: '#fff'
    }
  }
}
</script>

<style scoped lang="scss">
.top-header-strip__background {
  background-color: $c--black;
  width: 100%;
  height: 40px;
  pointer-events: all;
  position: relative;
}

.top-header-strip__gap-filler {
  @include top-gap-filler($background: $c--black, $z-index: 2);
}

.top-header-strip {
  align-items: center;

  @include page-layout;
  @include page-paddings;

  grid-template-rows: 40px;

  .top-header-strip__text {
    font-family: $font-poppins;
    font-size: 18px;

    @include tablet {
      font-size: 16px;
    }

    &.fm-group {
      color: #75a6e8;
    }

    &.forexlive {
      color: #fffeff;
    }
  }

  .top-header-strip__content-wrapper {
    @include desktop-sm {
      grid-column: 1 / 4;
    }
  }

  .top-header-strip__content-wrapper,
  .top-header-strip__forexlive-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .forexlive-icon {
    margin-left: 6px;
    margin-bottom: 4px;
  }
}
</style>
